import React from 'react';

function CountryPage({ country, countryCode }) {

    if (countryCode === "ag") {
        return (
            <div className="country-page">
                <div className="country-page">

                    <h1>MateCoin</h1>
                    <p>
                        Bienvenido a MateCoin en <strong>{country}</strong>!
                    </p>
                    <p>Estén atentos a las actualizaciones del poder de la economía libre</p>
                    <h3>Muy pronto..</h3>
                </div>
            </div>
        );
    } else if (countryCode === "us") {
        return (
            <div className="country-page">

                <div className="country-page">
                    <h1>MateCoin</h1>
                    <h2>The Power of Free Economy
                    </h2>

                    <p>Discover all products and services in the crypto of your choice by clicking   <a href="http://www.matecoinmarket.com:3000/" target="_blank" rel="noopener noreferrer">
                        here
                    </a>.
                    </p>
                    <h3>Coming soon..</h3>

                </div>
            </div>
        );
    } else if (countryCode === "de") {
        return (
            <div className="country-page">
                <div className="country-page">
                    <h1>MateCoin</h1>
                    <h2>Die Kraft der freien Wirtschaft

                    </h2>

                    <p>Entdecken Sie alle Produkte und Dienstleistungen mit Ihrer bevorzugten Krypto.
                    </p>
                    <h3>Kommt bald..</h3>

                </div>
            </div>
        );
    } else if (countryCode === "au") {
        return (
            <div className="country-page">

                <div className="country-page">
                    <h1>MateCoin</h1>
                    <h2>The Power of Free Economy
                    </h2>

                    <p>Explore all products and services with the crypto you prefer.
                    </p>
                    <h3>Coming soon..</h3>
                </div>
            </div>
        );
    } else if (countryCode === "hk") {
        return (
            <div className="country-page">
                <div className="country-page">
                    <h1>MateCoin</h1>
                    <h2>自由经济的力量

                    </h2>
                    <p>用你喜欢的加密货币发现所有商品和服务。

                    </p>
                    <h3>即将推出..</h3>
                </div>
            </div>
        );
    } else
        return (
            <div className="country-page">
                <div className="country-page">
                    <h1>MateCoin</h1>
                    <h2>قوة الاقتصاد الحر
                    </h2>
                    <p>اكتشف جميع المنتجات والخدمات بالعملة الرقمية التي تفضلها.
                    </p>
                    <h3>قريباً!</h3>
                </div>
            </div>
        );
}

export default CountryPage;
